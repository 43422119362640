import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled, } from '@mui/material/styles';
import Slide from '@mui/material/Slide';

const SectionContainerRoot = styled(Container, {
  name: 'MdlSectionContainer', // The component name
  slot: 'root', // The slot name
})(({ theme, ownerState }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('xs')]: {
    paddingTop: '4rem',
    paddingBottom: '4rem',
  },
  [theme.breakpoints.up('sm')]: {
    paddingTop: '6rem',
    paddingBottom: '6rem',
  },
  backgroundColor: theme.palette.background.normal,
  ...(ownerState.shade==='dark' && {
    backgroundColor: theme.palette.background.dark,
  }),
  ...(ownerState.shade ==='light' && {
    backgroundColor: theme.palette.background.light,
  }),
  position: 'relative',
  alignItems: 'center',
  width: '100%',

}));

const SectionContainerTitleBar = styled(Box, {
  name: 'MdlSectionContainer',
  slot: 'titleBar',
})(({ theme }) => ({
  width: { xs: '85%', md: '70%' },
  textAlign: 'center',
}));

const SectionContainerTitleLabel = styled(Typography, {
  name: 'MdlSectionContainer',
  slot: 'titleLabel',
})(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: '300',
  textTransform: 'uppercase',
}));

const SectionContainer = React.forwardRef(function SectionContainer(props, ref) {
  const { id, sectionTitle, children, ...other } = props;
  const ownerState = { ...props };

  return (
    <SectionContainerRoot id={id} maxWidth='xl' ref={ref} ownerState={ownerState} {...other}>
      <SectionContainerTitleBar ownerState={ownerState}>
        <SectionContainerTitleLabel variant="h4" ownerState={ownerState}>
          {sectionTitle}
        </SectionContainerTitleLabel>
      </SectionContainerTitleBar>
      {children}
    </SectionContainerRoot>
  );
});

export default SectionContainer;
