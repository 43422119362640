import * as React from 'react';
import { styled, } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CodeIcon from '@mui/icons-material/Code';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import ApiIcon from '@mui/icons-material/Api';
import CloudIcon from '@mui/icons-material/Cloud';
import EngineeringIcon from '@mui/icons-material/Engineering';
import {Reactjs} from '@styled-icons/remix-line/Reactjs';
import {Nodejs} from '@styled-icons/boxicons-logos/Nodejs';
import {Aws} from '@styled-icons/fa-brands/Aws';
import {Html5} from '@styled-icons/boxicons-logos/Html5';
import {Css3} from '@styled-icons/boxicons-logos/Css3';
import {Javascript} from '@styled-icons/boxicons-logos/Javascript';
import {Git} from '@styled-icons/boxicons-logos/Git';

const serviceItems = [
  {
    icon: <CodeIcon sx={{ fontSize: 40, color: "secondary.contrastText"}}/>,
    title: 'Web Development',
    description:
      'Create dynamic, responsive, and secure web applications tailored to your business needs.',
  },
  {
    icon: <TabletMacIcon sx={{ fontSize: 40, color: "secondary.contrastText" }}/>,
    title: 'Mobile App Development',
    description:
      'Design and develop engaging mobile apps for iOS and Android platforms that provide exceptional user experiences.',
  },
  {
    icon: <PersonalVideoIcon sx={{ fontSize: 40, color: "secondary.contrastText" }}/>,
    title: 'Desktop Application Development',
    description:
      'Build powerful desktop software with intuitive interfaces and robust functionalities.',
  },
  {
    icon: <ApiIcon sx={{ fontSize: 40, color: "secondary.contrastText" }}/>,
    title: 'System Integration and & API Development',
    description:
      'Seamlessly integrate diverse systems and technologies, and develop custom APIs to streamline operations and improve efficiency.',
  },
  {
    icon: <CloudIcon sx={{ fontSize: 40, color: "secondary.contrastText" }}/>,
    title: 'Cloud Solutions',
    description:
      'Develop scalable cloud applications that offer flexibility, accessibility, and enhanced performance.',
  },
  {
    icon: <EngineeringIcon sx={{ fontSize: 40, color: "secondary.contrastText" }}/>,
    title: 'Enterprise/Solutions Architecture',
    description:
      'Design and implement comprehensive architecture strategies that align with your business objectives, ensuring a cohesive and efficient IT environment.',
  },
];

const StyledGrid = styled(Grid)`
  ${({ theme }) => `
  cursor: pointer;
transition: ${theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.standard,
  })};
  &:hover {
    transform: scale(1.05);
  }
  `}
`;


const toolItems = [
  { icon: <Reactjs size= "48" color="white" />, title: 'React.js' },
  { icon: <Nodejs size= "48" color="white" />, title: 'Node.js' },
  { icon: <Aws size= "48" color="white" />, title: 'AWS' },
  { icon: <Html5 size= "48" color="white" />, title: 'HTML' },
  { icon: <Css3 size= "48" color="white" />, title: 'CSS' },
  { icon: <Javascript size= "48" color="white" />, title: 'JavaScript' },
  { icon: <Git size= "48" color="white" />, title: 'Git' },
];

export default function Technology() {
  return (
    <Box
      id="technology"
      maxWidth="1x"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        bgcolor: 'background.dark', 
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { xs: '85%', md: '70%' },
            textAlign: 'center',
          }}
        >
        <Typography
          variant="h4" 
          color="text.primary"
          sx={{
            color: 'secondary.contrastText',
            fontWeight: '300',
          }}
      >
         OUR&nbsp;
          <Typography 
            component="span"
            variant="h4"
            sx={{
              color: 'secondary.contrastText',
              fontWeight: '300',
            }}
          >
            SERVICES
          </Typography>
        </Typography>
          <Typography variant="body1" color='secondary.contrastText' sx={{ textAlign: 'justify', pt: 3}}>

          </Typography>
        </Box>
        <Grid 
          container spacing={2.5}
          sx={{
            width: { xs: '100%', md: '70%' },
          }}
        >
          {serviceItems.map((item, index) => (
            <StyledGrid item xs={12} sm={6} md={6} key={index}>
              <Stack
                direction="column"
                component={Box}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                }}
              >
                <Box align='left' >{item.icon}</Box>
                <div>
                  <Typography variant="h5" fontWeight="medium" color="text.primary" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body1" color="secondary.contrastText" >
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </StyledGrid>
          ))}
        </Grid>
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
            mt: { xs: 4, sm: 6 },
          }}
        >
          <Typography component="h3" variant="h5" color='secondary.contrastText'>
            TOOLS & LANGUAGES
          </Typography>
        </Box>
        <Grid 
          container spacing={0.5} 
          columns={7} 
          sx={{
            width: { xs: '100%', md: '70%' },
          }}
        >
          {toolItems.map((item, index) => (
            <StyledGrid item xs={2} md={1} key={index}>
              <Stack
                direction="column"
                color="primary"
                component={Box}
                spacing={1}
                useFlexGap
                sx={{
                  p: 1,
                  height: '100%',
                  background: 'transparent',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ fontSize: '3rem' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom align="center" color="secondary.contrastText">
                    {item.title}
                  </Typography>
                </div>
              </Stack>
            </StyledGrid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
