import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import * as React from 'react';
import MlIcon from '../MlSvgIcon';
import LoggersIcon from '../LoggersSvgIcon';
import StartIcon from '@mui/icons-material/Start';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const menuItems = [
  {
    title: 'ABOUT US',
    id: 'aboutus',
  },
  {
    title: 'OUR PROCESS',
    id: 'ourprocess',
  },
  {
    title: ' OUR SERVICES',
    id: 'technology',
  },
  {
    title: 'YOUR QUESTIONS',
    id: 'faq',
  },
  {
    title: 'CONTACT US',
    id: 'contactus',
  },
]

function AppAppBar() {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDropMenu = (sectionId) => {
    handleClose();
    scrollToSection(sectionId);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
    }
  };

  return (
    <AppBar position="fixed" sx={{ px: "20px" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <MlIcon color="secondary" sx={{ fontSize: { xs: 32, md: 64 }, display: 'flex' }} />
          <Divider orientation='vertical' variant='middle' width='40px' flexItem='true'  sx={{ display: { xs: 'none', md: 'flex' }, opacity: 0.6 }}/>
          <Stack direction="column" justifyContent="flex-end" alignItems="stretch" spacing={0} sx={{ ml: '10px', }}>
            <Typography
            variant="h4"
            noWrap
            component="h6"
            color="primary.contrastText"
            sx={{
              mt: 4,
              px: '12px',
              display: { xs: 'none', md: 'flex' },
              fontWeight: '300',
              letterSpacing: '.2rem',
              textDecoration: 'none',
              textAlign: 'left',
              fontStretch: 'ultra-expanded',
            }}
            >
              MICHAEL LUND
              <Typography
              variant="h4"
              component="span"
              fontWeight='light'
              sx={{
                ml: 1,
                color: '#b0bec5',
                display: 'flex',
                fontWeight: '100',
                letterSpacing: '.1rem',
                textDecoration: 'none',
                textAlign: 'center',
                fontStretch: 'ultra-expanded',
              }}
              >
                CONSULTING
              </Typography>
            </Typography>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {menuItems.map((item, index) => (

                <MenuItem
                  onClick={() => scrollToSection(item.id)}
                  sx={{ mt: 1, py: '6px', px: '12px' }}
                >
                  <Typography 
                    variant="caption" 
                    color="primary.contrastText" 
                    fontWeight="bold" 
                    letterSpacing=".2rem"
                    sx={{ fontStretch: 'ultra-expanded' }}
                  >
                    {item.title}
                  </Typography>
                </MenuItem>
              ))}
            </Box>
          </Stack>
          <Button 
            color="secondary"
            variant="contained"
            width="1" 
            sx={{ whiteSpace: 'nowrap', marginLeft: 'auto', display: { xs: 'none', lg: 'flex' }}}
            onClick={() => { scrollToSection('contactus')}}
          >
            Get started
          </Button>
          <IconButton
            id="menu-id"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{display: { xs: 'flex', md: 'none', marginLeft: 'auto' }}}
          >
            {Boolean(anchorEl) ? <CloseIcon sx={{ fontSize: 32}}/> : <MenuIcon sx={{ fontSize: 32}}/> }
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{display: { xs: 'flex', md: 'none' }}}
          >
            {menuItems.map((item, index) => (

            <MenuItem
              onClick={() => handleDropMenu(item.id)}
            >
              <Typography width="540px" align="center" variant="button" transform="scaleX(2.5)" fontWeight="bold" letterSpacing=".2rem">
                {item.title}
              </Typography>
            </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </Container >
    </AppBar>
  );
}

/*
function AppAppBar() {

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div>
      <AppBar
        position="fixed"
        top="0"
        color="secondary"
        sx={{
          boxShadow: 0,
          //bgcolor: 'transparent',
          backgroundImage: 'none',
          //mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              ml: '-18px',
              px: 0,
              height: 120,
            }}
          >
            <MlIcon color='primary' sx={{ fontSize: 75, m: '0', p: '0' }} />
            <Stack spacing={0}>
              <Typography
                variant="h4"
                noWrap
                component="h6"
                color="primary.contrastText"
                sx={{
                  mt: 4,
                  ml: 17,
                  display: 'flex',
                  fontWeight: '300',
                  letterSpacing: '.2rem',
                  transform: 'scaleX(1.3)',
                  textDecoration: 'none',
                }}
              >
                MICHAEL LUND
                  <Typography
                  variant="h4"
                  component="span"
                  fontWeight='light'
                  sx={{
                    ml: 1,
                    color: '#b0bec5',
                    display: 'block',
                    fontWeight: '100',
                    letterSpacing: '.1rem',
                    textDecoration: 'none',
                    textAlign: 'center',
                  }}
                >
                  CONSULTING
                </Typography>
              </Typography>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                {menuItems.map((item, index) => (

                  <MenuItem
                    onClick={() => scrollToSection(item.id)}
                    sx={{ mt: 1, py: '6px', px: '12px' }}
                  >
                    <Typography variant="button" color="primary.contrastText" transform="scaleX(1.3)" fontWeight="normal" letterSpacing=".2rem">
                      {item.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
            </Stack>
          </Box>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};
*/
export default AppAppBar;
