import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const items = [
  {
    id: '1',
    question: 'What services do you offer?',
    answer: 'We offer a comprehensive range of software development services, including custom software development, mobile app development, web development, software maintenance, and support. Additionally, we provide consulting services, project management, and software integration.',
  },
  {
    id: '2',
    question: 'How do you handle changes in project scope?',
    answer: 'We follow an agile development methodology, allowing us to adapt to changes in project scope. We will discuss any requested changes with you, assess their impact on the timeline and budget, and proceed with your approval.',
  },
  {
    id: '3',
    question: 'What is your pricing model?',
    answer: 'We use 3 models. We use a fixed price model for projects with well-defined requirements and scope, an hourly model for projects with evolving requirements or ongoing development needs and a retainer model for long-term engagements and ongoing support.',
  },
  {
    id: '4',
    question: 'Can you work with our existing systems and technology stack?',
    answer: 'Yes, we have experience integrating new software with existing systems and technology stacks. We will assess your current environment and develop a solution that works seamlessly with your existing infrastructure.',
  },
  {
    id: '5',
    question: 'What is the typical timeline for a software development project?',
    answer: 'The timeline for a software development project varies based on its complexity and scope. We provide an estimated timeline during the proposal phase, which is refined as the project progresses. Typical projects can range from a few weeks for small applications to several months for larger systems.',
  },
  {
    id: '6',
    question: 'What industries do you serve?',
    answer: 'We have experience working with clients in various industries, including healthcare, financial services, e-commerce, education, digital imaging, factory automation and more. Our team is adaptable and capable of understanding the unique needs of different sectors.',
  },
  
];


export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      maxWidth="x1"
      sx={{
        bgcolor: 'background.light', 
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { xs: '85%', md: '70%' },
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h4" 
          color="text.primary"
          sx={{
            color: 'text.primary',
            fontWeight: '300',
          }}
      >
         YOUR&nbsp;
          <Typography 
            component="span"
            variant="h4"
            sx={{
              color: 'text.primary',
              fontWeight: '300',
            }}
          >
            QUESTIONS
          </Typography>
        </Typography>
      </Box>
      <Box 
        sx={{
          width: { xs: '100%', md: '70%' },
        }}
      >
        {items.map((item, index) => (
          <Accordion sx={{ border: 0, bgcolor: 'background.light' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography component="h3" variant="h6" sx={{ fontWeight: 'normal' }}>
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ textAlign: 'justify', maxWidth: '100%', pl: '5%' }}
              >
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
