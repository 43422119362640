import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ContactUs() {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      name: name,
      email: email,
      message: message,
    }

    const rawResponse = await fetch('https://y73kuoeqlk.execute-api.us-east-2.amazonaws.com/Prod/', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const content = await rawResponse.json();
    setName("");
    setEmail("");
    setMessage("");
  
    handleOpen();
  };

  return (
    <Container
      id="contactus"
      maxWidth= "x1"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: { xs: '85%', md: '70%' },
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h4" 
          color="text.primary"
          sx={{
            color: 'text.primary',
            fontWeight: '300',
          }}
        >
         CONTACT&nbsp;
          <Typography 
            component="span"
            variant="h4"
            sx={{
              color: 'text.primary',
              fontWeight: '300',
            }}
          >
            US
          </Typography>
        </Typography>
        <Typography variant="body1" color="text.primary" sx={{ pt: 2, textAlign: 'justify'}}>
            Please fill out the form below to get started. One of our consultants will reach out to you as soon as possible to discuss your needs and how we can help.
            We look forward to partnering with you on your path to digital success.
        </Typography>
      </Box>

      <Card 
        raised="false" 
        elevation='6' 
        component="form" 
        onSubmit={handleSubmit} 
        sx={{ 
          bgcolor: 'background.light', 
          m: '5%', 
          maxWidth: '800px', 
          width: { xs: '95%', md: '70%' },
        }}
      >
        <Stack spacing={2} alignItems="flex-end" sx={{  p:"5%" }}>
          <Typography
                variant="h5"
                width="100%"
                sx = {{ textAlign: 'center', pb: "3%" }}
          >
            GET STARTED
          </Typography>
          <TextField
            required
            id="name"
            label="Name"
            value={name}
            variant="outlined"
            fullWidth
            color="primary"
            sx={{
              bgcolor: 'background.default',
            }}
            onChange={(e) => { setName(e.target.value); }}
          />
          <TextField
            required
            id="email"
            label="Email"
            value={email}
            type="email"
            variant="outlined"
            fullWidth
            sx={{
              bgcolor: 'background.default',
            }}
            onChange={(e) => { setEmail(e.target.value); }}
          />
          <TextField
            required
            id="description"
            label="Message"
            value={message}
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            sx={{
              bgcolor: 'background.default',
            }}
            onChange={(e) => { setMessage(e.target.value); }}
          />
          <Button type="submit" variant="contained" color="secondary">
            Submit
          </Button>
        </Stack>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="confirmation-modal-title"
        aria-describedby="confirmation-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="confirmation-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
            Thank you for your interest. One of our consultants will reach out to you shortly.
          </Typography>
          <Stack direction="row" spacing={2} sx={{ mt: 4, justifyContent: 'center' }}>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Dismiss
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Container>

  );
}
