import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function AboutUs() {

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box
      id="aboutus"
      sx={{
        width: '100%',
        backgroundSize: '100% 40%',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} alignItems="center" useFlexGap sx={{ width: { xs: '85%', md: '70%' }}}>
          <Typography
            align="justify" 
            variant="body1"
            fontStyle="italic"
            color="text.primary"
            sx={{ mb: { xs: 2, sm: 4 }}}
          >
            At&nbsp;
              <Typography variant="body1" component="span" color="text.secondary" fontStyle="italic" fontWeight="bold">
                Michael Lund Consulting
              </Typography>
            , we specialize in delivering bespoke software development services that cater to a diverse range of platforms and needs, including web, mobile, desktop, system, and cloud environments. Our consulting company is dedicated to transforming your unique business challenges into innovative and efficient solutions that drive success.
          </Typography>
          <Button 
            color="secondary"
            variant="contained"
            sx={{ display: { xs: 'flex', lg: 'none' }}}
            onClick={() => { scrollToSection('contactus')}}
          >
            Get started
          </Button>
        </Stack>
      </Container>
    </Box>
  );
}
