import * as React from 'react';
import {useEffect } from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Technology from './components/Technology';
import Pricing from './components/Pricing';
import AboutUs from './components/AboutUs';
import OurProcess from './components/OurProcess';
import FAQ from './components/FAQ';
import ContactUs from './components/ContactUs';
import SectionContainer from './components/SectionContainer';
import Footer from './components/Footer';
import getLPTheme, { gray } from './getLPTheme';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-MKLJDBTG51";

ReactGA.initialize(TRACKING_ID);

const mlTheme = createTheme({
  typography: {
    fontFamily: [
      'Roboto-Flex',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#455a64', //blueGrey:700
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff6f00', //Amber:800
      contrastText: '#ffffff',
    },
    text: {
      primary: '#455a64',
      secondary: '#ff6f00',
    },
    background: {
      light: '#eceff1', //blueGrey:50
      dark: '#90a4ae', //blueGrey:300
      paper: '#ffffff',
      default: '#ffffff',
    },
    divider:  '#ffffff',
  },
});

export default function LandingPage() {
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
  }, []);

  //sx={{ bgcolor: 'grey' }}
  return (
    <ThemeProvider theme={mlTheme}>
      <CssBaseline />
      <AppAppBar />
      <Box >
        <AboutUs />
        <OurProcess />
        <Technology />
        <FAQ />
        <ContactUs />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
