import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function MlSvgIcon(props) {
  return (
    <SvgIcon {...props} 
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={4}
        stroke="currentColor"
      >
        <mask id="myMask">
{/*          <rect x="-8" y="-8" width="40" height="40" fill="white" />*/}

          <path
            strokeLinecap="square"
            strokeLinejoin="square"
            d="M 0,4 L 6,10"
            stroke="black"
            strokeWidth={2}
          />
        </mask>

{/*        <rect x="0" y="0" width="24" height="24" fill="white" /> */}
        <path
          strokeLinecap="square"
          strokeLinejoin="square"
          d="M 20,20 L 20,4 L 12,12 L 4,4 L 4,20 L 14,20"
        />
      </svg>
    </SvgIcon>
/*
          mask="url(#myMask)"
    <SvgIcon {...props} >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={3}
        stroke="currentColor"
      >
        <path
          strokeLinecap="square"
          strokeLinejoin="butt"
//          d="M 20,20 L 20,4 L 12,12 L 4,4 L 4,20 L 14,20"
//          d="M 4,4 L 4,20 L 15,20 M 20,20 L 20,4 L 15,9 L 10, 4 L 10,15"
        />
        <path
          strokeLinecap="butt"
          strokeLinejoin="butt"
            d="M 4,4 L 12,8 L 20,4"
        />
        <path
          strokeLinecap="square"
            d="M 20,4 L 20,20"
        />
        <path
          strokeLinecap="butt"
            d="M 6,9 L 6,19 L 15,19"
        />
      </svg>
    </SvgIcon>
*/
  );
}
