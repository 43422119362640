import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepConnector from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { styled, } from '@mui/material/styles';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import SectionContainer from './SectionContainer';


const steps = [
  { 
    label: 'Initial Consultation', 
    description: 'We meet with you to understand project needs, assess feasibility, and define scope and deliverables. All requirements are documented for clarity and alignment.',
  },
  { 
    label: 'Proposal and Agreement',
    description: 'A detailed proposal is provided, contract terms are finalized, and a kickoff meeting is held to introduce the team and discuss the plan.',
  },
  { 
    label: 'Planning and Design', 
    description: 'A project plan is created, wireframes and prototypes are developed for visualization and preview, and the design is iterated on and approved based on your feedback.',
  },
  { 
    label: 'Development', 
    description: 'Software architecture is established, development is done in agile sprints, features are implemented based on priority and feedback, and new code is optionally integrated frequently with automated tests for stability.',
  },
  { 
    label: 'Testing', 
    description: 'Unit tests ensure component functionality, integration tests verify module cooperation, user acceptance testing involves you to meet requirements, and any identified issues are fixed.',
  },
  { 
    label: 'Deployment', 
    description: 'A detailed deployment plan is created, the production environment is set up, any necessary data migrations is handled , the software is deployed, and immediate post-launch support is provided.',
  },
];

const ProcessConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    marginLeft: 25,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.lineVertical}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.primary.main,
  },
}));

const ProcessIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: 'unset',
  zIndex: 1,
  border: 3,
  borderColor: ownerState.active ? theme.palette.secondary.main : theme.palette.primary.main,
  borderStyle: 'solid',
  borderWidth: '1.5px',
  color: ownerState.active ? theme.palette.secondary.main : theme.palette.primary.main,
  width: 50,
  height: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
  
function ProcessIcon(props) {
  const { active, completed, className } = props;

  return (
    <ProcessIconRoot ownerState={{ completed, active }} className={className}>
      {props.icon}
    </ProcessIconRoot>
  );
}

const ProcessContent = styled(StepContent)(({ theme, ownerState }) => ({
  marginLeft: 25, // half icon
  paddingLeft: 8 + 25, // margin + half icon
  paddingRight: 8,
  borderLeft: '1px solid',
  borderLeftColor: theme.palette.primary.main,
}));
  
export default function Testimonials() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <SectionContainer id="ourprocess" sectionTitle="Our Process" shade="light" >
      <Box sx={{ width: { xs: '90%', md: '50%' }, mt: 4 }}>
        <Stepper nonLinear activeStep={activeStep} orientation="vertical" connector={<ProcessConnector />} >
          {steps.map((step, index) => (
            <Step 
              key={step.label}
            >
              <StepButton 
                color={activeStep === index ? "text.secondary" : "text.primary" } 
                onClick={handleStep(index)}
              >
                <StepLabel StepIconComponent={ProcessIcon}>
                  <Typography 
                    variant="h6" 
                    color={activeStep === index ? "text.secondary" : "text.primary"} 
                    sx={{ 
                      textAlign: 'left', 
                      fontWeight: 'normal',
                      pl: 2,
                    }}
                  >
                    {step.label}
                  </Typography>
                </StepLabel>
              </StepButton>
                <ProcessContent>
                  <Typography
                    color={activeStep === index ? "text.secondary" : "text.primary"}
                    sx={{
                      pl: 2
                    }}
                  >
                    {step.description}
                  </Typography>
                </ProcessContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </SectionContainer>
  );
}
